<template>
  <el-container class="project-frame">
    <div class="project-header">
      <AHead />
    </div>
    <div class="project-container">
      <div class="project-aside" :class="isFacility ?'pc_width':''">
        <AsideNav @handleNav="handleNav" />
      </div>
      <div class="project-main">
        <!-- <div class="flex flex-ac content-top">
          <BreadCrumb :item="crumbs" />
        </div> -->
        <el-tabs v-model="activeValue" type="card" @tab-click="handleClick">
          <el-tab-pane v-for="(item,index) in editableTabs" :key="index" :name="item.url">
            <span slot="label" class="tab_name">{{item.name}}
              <i class="el-icon-circle-close ml-10" :class="activeValue == item.url?'':'tab_close'"
                @click="handleTabsEdit(item,'remove')" v-if="item.url != '/home'"></i>
            </span>
          </el-tab-pane>
        </el-tabs>
        <div class="content-data">
          <keep-alive v-if="$route.meta.keepAlive">
            <router-view></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
        </div>
      </div>
    </div>
  </el-container>
</template>

<script>
import AHead from "@/components/Frame/AHead.vue"
import AsideNav from "@/components/Frame/AsideNav"
import { isFacility } from "@/utils/util"
export default {
  name: 'ProjectTop',
  components: {
    AHead, AsideNav,
    "BreadCrumb": (resolve) => require(["@/components/Frame/BreadCrumb"], resolve),
  },
  data () {
    return {
      isFacility: false,
      crumbs: [],
      activeValue: 0,
      editableTabs: [],
    }
  },
  mounted () {
    this.isFacility = isFacility()
    let rout = this.$route
    let home = { name: "首页", url: "/home" }
    this.activeValue = rout.path
    if (home.url == rout.path) {
      this.editableTabs = [home]
    } else {
      this.editableTabs = [home, {
        url: rout.path,
        name: rout.meta.title,
      }]
    }
  },
  methods: {
    //自定义 面包屑
    getCustomCrumb (e) {
      if (e != null && e != '' && e != undefined) {
        this.crumbs = e
      }
    },
    //点击导航
    handleNav (data) {
      this.handleTabsEdit(data, 'add')
    },
    //点击标签页
    handleClick (tab, event) {
      if (this.$route.path != tab.name) {
        this.$router.push(tab.name)
      }
    },
    //新增 删除 标签页
    handleTabsEdit (target, action) {
      if (action === 'add') {
        let index = this.editableTabs.findIndex(ele => { return ele.url == target.url })
        if (index == -1) {
          this.editableTabs.push(target)
        }
        this.activeValue = target.url
      }
      if (action === 'remove') {
        let tabs = this.editableTabs
        let activeName = this.activeValue
        if (activeName === target.url) {
          tabs.forEach((tab, index) => {
            if (tab.url === target.url) {
              let nextTab = tabs[index + 1] || tabs[index - 1]
              if (nextTab) {
                activeName = nextTab.url
              }
            }
          })
          this.activeValue = activeName
          this.$router.push(activeName)
        }
        this.editableTabs = tabs.filter(tab => tab.url !== target.url)
      }
    },
  }
}
</script>

<style lang="less" scoped>
.project-frame {
  display: flex;
  flex-direction: column;
  .project-header {
    background-color: #0076f6;
    height: 60px;
    flex: none;
    overflow: auto;
  }
  .project-container {
    display: flex;
    flex: 1;
    .project-aside {
      background: #fff;
      flex: none;
      max-width: 300px;
      overflow: auto;
    }
    .pc_width {
      width: 300px;
    }
    .project-main {
      flex: 1;
      box-sizing: border-box;
      overflow: auto;
      margin-left: 12px;
      background: #fff;
      .tab_name {
        display: flex;
        align-items: center;
        .tab_close {
          display: none;
        }
      }
      .el-tabs__item:hover .tab_close {
        display: block;
      }
      .content-top {
        box-sizing: border-box;
        height: 60px;
        padding: 0 30px;
        background: #fff;
      }
      .content-data {
        box-sizing: border-box;
        height: calc(100vh - 120px);
        background: #f0f2f5;
      }
    }
  }
}
</style>
