<!--
  顶部
-->
<template>
  <div class="head" :class="logoTitle == '乐芒抽盒机'?'head-bg1':(logoTitle == '一起抽盒鸭'?'head-bg2':'head-bg3')">
    <div class="header-title">
      {{logoTitle + '管理系统'}}
    </div>
    <div class="user-info">
      <i class="el-icon-bell"></i>
      <el-dropdown trigger="click" @command="handleCommand">
        <div class="avatar pointer">
          <i class="el-icon-user-solid i-img"></i>
          <span>{{userData.userName}}</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-edit" command="password">修改密码</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <i class="el-icon-switch-button pointer" title="退出" @click="logout"></i>
    </div>
  </div>
</template>

<script>
import { clearLoginInfo } from '@/utils'
import { mapState } from 'vuex'

export default {
  name: "Head",
  computed: {
    ...mapState(['userInfo'])
  },
  created () {
    this.logoTitle = window._config.logoTitle || '后端'
    this.getUserInfo()
  },
  data () {
    return {
      userData: {},
      logoTitle: ''
    }
  },
  methods: {
    // 从缓存中读取用户消息
    getUserInfo () {
      let userInfo = localStorage.getItem('userInfo')
      if (userInfo) {
        this.userData = JSON.parse(userInfo)
      }
    },
    //修改密码
    handleCommand (command) {
      if (command == 'password') {
        this.$prompt('请输入新密码', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          if (value) {
            this.$http.post('/userInfo/editPasswordByid', {
              id: this.userData.id,
              password: value,
            }).then((result) => {
              this.$message({
                type: 'success',
                message: '修改成功！'
              })
            }).catch((err) => { })
          } else {
            this.$message({
              type: 'error',
              message: '新密码不能为空！'
            })
          }
        }).catch(() => {
        })
      }
    },
    // 退出
    logout () {
      this.$http.post('/userInfo/logout').then(({ data: result }) => {
        if (result.code == 1) {
          clearLoginInfo()
          this.$router.replace({ path: '/login' })
        }
      }).catch((err) => { })
    }
  }
}
</script>

<style scoped lang="less">
.head-bg1 {
  background-color: #ad92f4 !important;
}
.head-bg2 {
  background-color: #ffa1a2 !important;
}
.head-bg3{
	background-color: #FF8C00 !important;
}
.head {
  height: 60px;
  box-sizing: border-box;
  padding: 0 30px;
  background-color: #0076f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header-title {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    line-height: 60px;
  }
  .user-info {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
    .avatar {
      display: flex;
      align-items: center;
      padding: 0 33px;
      color: #fff;
      .i-img {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        margin-right: 12px;
      }
    }
  }
}
</style>
