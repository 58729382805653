<!-- 侧边导航栏 -->
<template>
  <div class="AsideNav">
    <el-menu default-active="2" class="el-menu-vertical-demo menu" :default-active="$route.path" :collapse="isCollapse"
      collapse-transition unique-opened>
      <template v-for="item in menuList">
        <!-- 没有展开项，只有一个一级菜单 -->
        <el-menu-item class="el-submenu menu-item" v-if="item.children.length == 0" :key="item.id" :index="item.url">
          <template slot="title">
            <div class="sub-menu-item" @click="$emit('handleNav',item)">
              <router-link class="item_link" :to="item.url">
                <i v-if="item.icon" :class="['icon', item.icon]" class="image-icon"></i>
                <span>{{item.name}}</span>
              </router-link>
            </div>
          </template>
        </el-menu-item>
        <!-- 有展开项，含有二级菜单 -->
        <el-submenu v-else :key="item.id" :index="item.id + ''">
          <template slot="title">
            <div class="sub-menu-title">
              <i v-if="item.icon" :class="['icon', item.icon]" class="image-icon img-i"></i>
              <span>{{item.name}}</span>
            </div>
          </template>
          <el-menu-item class="menu-item" v-for="child in item.children" :key="child.id" :index="child.url">
            <template slot="title">
              <div class="sub-menu-item" @click="$emit('handleNav',child)">
                <router-link class="link" :to="child.url">
                  <i v-if="child.icon" :class="['icon', child.icon]" class="image-icon img-i"></i>
                  <span>{{child.name}}</span>
                </router-link>
              </div>
            </template>
          </el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { moduleRoutes } from "@/router/index"
import { isFacility } from "@/utils/util"
import { mapState } from 'vuex'
export default {
  name: 'AsideNav',
  computed: {
    menuList () {
      let menuList = this.$store.state.menu.menuList
      let home = { children: [], icon: "el-icon-s-home", id: 1, name: "首页", url: "/home" }
      return [home, ...menuList]
    }
  },
  data () {
    return {
      isCollapse: false,
      // menuList: window._config.menuList,
      // menuList: this.$router.options.routes[0].children,
    }
  },
  created () { },
  mounted () {
    console.log(isFacility(), '是否pc')
    if (isFacility()) {
      this.isCollapse = false
    } else {
      this.isCollapse = true
    }
  },
}
</script>

<style scoped lang="less">
// 二级菜单的父标题
.submenuTitle(@f_color: #333) {
  font-size: 16px;
  color: @f_color;
  // font-weight: bold;
}
// 二级菜单
.submenuItemTitle(@f_color: #666) {
  font-size: 16px;
  color: @f_color;
  font-weight: 400;
}
.AsideNav {
  height: calc(100vh - 60px);
  box-sizing: border-box;
  background-color: #fff;
  border-right: 1px solid #e8e8e8;
  .menu {
    padding: 20px 0;
  }
  .image-icon {
    margin-right: 10px;
  }
  .item_link {
    display: flex;
    align-items: center;
    background-color: #fff !important;
  }
  .link {
    padding: 0 30px;
    display: flex;
    align-items: center;
  }
  .el-menu {
    border: none;
  }
  .sub-menu-title {
    height: 40px;
    line-height: 40px;
  }
  .el-menu-item {
    height: auto;
    line-height: normal;
    margin-bottom: 10px;
    padding: 0 20px !important;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  // 二级菜单 - 父标题 - 默认样式
  ::v-deep .el-submenu {
    margin-bottom: 10px;
    .el-submenu__title {
      padding-left: 20px !important;
      height: auto;
      line-height: normal;
      margin-bottom: 10px;
      .submenuTitle();
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
  // 二级菜单 - 父标题 - 激活样式
  .el-submenu.is-active .sub-menu-title {
    .submenuTitle(#0076f6);
    .img-i {
      color: #0076f6;
    }
  }
  // 二级菜单的父标题 - 悬浮状态
  ::v-deep .el-submenu__title:hover {
    background-color: transparent !important;
  }
  // 二级菜单 - 默认样式
  .sub-menu-item {
    & > a {
      height: 40px;
      line-height: 40px;
      //display: block;
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-radius: 20px;
      .submenuItemTitle();
      & > img {
        width: 18px;
        height: 18px;
        vertical-align: middle;
      }
    }
  }
  // 二级菜单 - 激活样式
  .el-menu-item.is-active .sub-menu-item a {
    border-radius: 20px;
    background-color: rgba(0, 118, 246, 0.08);
    .submenuItemTitle(#0076f6);
  }
  // 二级菜单 - 悬浮样式
  .el-menu-item:hover {
    a {
      color: #0076f6;
    }
    .img-i {
      color: #0076f6;
    }
    background: transparent !important;
  }
}
</style>
